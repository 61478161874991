import React, { Component } from 'react';
import resourceUrl          from 'utils/resourceUrl';
import resourceHeaders      from 'utils/resourceHeaders';
import FormDataModal        from 'components/FormDataModal';

const anchor = '#download-responses-modal';

function getFilenameFromResponse(response) {
    const contentDisposition = response.headers.get('content-disposition');
    if (!contentDisposition) return 'form.csv';
    const matches = /filename="(.*?)"/g.exec(contentDisposition);

    return matches && matches.length > 1 ? matches[1] : 'form.csv';
}

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

class DownloadResponses extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen:    false,
      modalLoading: false,
    };

    this.handleDownload   = this.handleDownload.bind(this);
    this.handleOpenModal  = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  render() {
    const { recordId }                = this.props;
    const { modalOpen, modalLoading } = this.state;

    return (
      <React.Fragment>
        <FormDataModal  id={ recordId }
                        opened={ modalOpen }
                        loading={ modalLoading }
                        action='download'
                        onClose={ this.handleCloseModal }
                        onSubmit={ this.handleDownload }
                        { ...this.props } />
        <a onClick={ this.handleOpenModal } href={ anchor } >Download</a>
      </React.Fragment>
      );
  }

  async handleDownload(_, includeData) {
    this.setState({ modalLoading: true });

    const { state: { orgdomain: organization, auth: { authToken } },
            recordId, recordType } = this.props;

    const basePath  = '/organization/:organization/:recordType';
    
    if (includeData == 2) {
      const url       = resourceUrl(basePath, ':recordId/prepare_files', { organization, recordType, recordId });
      const headers   = resourceHeaders(authToken);
      try {
        const response  = await fetch(url, { method: 'POST', headers });
        this.setState({ modalLoading: false });
        this.handleCloseModal();
        if (!response.ok) {
          alert('You don\'t have permissions to initiate the export.');
        } else {
          alert('Your download is being prepared. It can take a while, depending on the number of files. You will receive an email with a link to download the files.');
        }
      } catch (error) {
        alert(error);
      }
    } else {
      const url       = resourceUrl(basePath, ':recordId.csv?include_data=:includeData', { organization, recordType, recordId, includeData });
      const headers   = resourceHeaders(authToken, (hs) => {
        hs['Content-Type'] = 'text/csv';
      });

      const response      = await fetch(url, { headers, responseType: 'blob' });
      const csv           = await response.blob();

      const csvUrl        = URL.createObjectURL(csv);
      const downloadLink  = document.createElement('a');
      const filename      = getFilenameFromResponse(response);

      downloadLink.href   = csvUrl;
      downloadLink.setAttribute('download', `${filename}`);
      downloadLink.click();

      URL.revokeObjectURL(csvUrl);

      this.setState({ modalLoading: false });
      this.handleCloseModal();
    }
  }

  handleOpenModal() {
    this.setState({ modalOpen: true });
  }

  handleCloseModal() {
    this.setState({ modalOpen: false });
  }
}

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default DownloadResponses;
