import React, { Component }                         from 'react';
import PropTypes                                    from 'prop-types';
import { Modal, Button, Header, Form, Checkbox }    from 'semantic-ui-react';
import uniqueId                                     from 'utils/uniqueId';
import WithPermission             from 'containers/WithPermission';

// -----------------------------------------------------
// Settings
// -----------------------------------------------------
const labels = {
  action: {
    clone:    'Copy',
    download: 'Download'
  },
  structureTitle: {
    clone:    'Structure only',
    download: 'Structure'
  },
  structureDescription: {
    clone:    'Creates a copy of the form, without copying any data.',
    download: 'Exports a csv file with just the structure of the form.'
  },
  dataTitle: {
    clone:    'Structure and data',
    download: 'Responses'
  },
  dataDescription: {
    clone:    'Creates a copy of the form, along with any data collected in client forms,' +
              ' public forms and internal forms (Resources).' +
              '<br/> This process might take a while. We\'ll send you an email when it\'s complete.',
    download: 'Exports a csv file with all data collected in client forms.'
  },
  filesTitle: {
    clone:    'Structure and data',
    download: 'Files'
  },
  filesDescription: {
    clone:    'Creates a copy of the form, along with any data collected in client forms,' +
              ' public forms and internal forms (Resources).' +
              '<br/> This process might take a while. We\'ll send you an email when it\'s complete.',
    download: 'Prepares an archive to download all files from form.'
  }
};

const icons = {
  clone:    'copy',
  download: 'download cloud'
};

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

function CheckboxLabel({ title, description }) {
  return (
    <span>
      <h3 style={{ marginBottom: '0' }} >{title}</h3>
      <p dangerouslySetInnerHTML={{ __html: description }} />
    </span>
  );
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class FormDataModal extends Component {
  constructor(props) {
    super(props);

    this.state = { includeData: 0 };

    this.formId       = uniqueId();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    const { includeData }     = this.state;
    const { opened,
            loading,
            action,
            onClose }         = this.props;

    return (
      <Modal open={ opened } onClose={ onClose } dimmer='inverted' closeIcon>
        <Header icon={ icons[action] } content={ labels.action[action] + ' form' } />
        <Modal.Content>
          <Form onSubmit={ this.handleSubmit } id={ this.formId }>
            <Form.Field>
              <Checkbox style={{ marginBottom: '20px' }}
                radio
                name='includeData'
                label={{ children: <CheckboxLabel title={ labels.structureTitle[action] }
                                                  description={ labels.structureDescription[action] } /> }}
                value='0'
                checked={includeData === 0}
                onChange={this.handleChange}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                radio
                label={{ children: <CheckboxLabel title={ labels.dataTitle[action] }
                                                  description={ labels.dataDescription[action] } /> }}
                name='includeData'
                value='1'
                checked={includeData === 1}
                onChange={this.handleChange}
              />
            </Form.Field>
            <WithPermission resource="ownerOnly">
              {action === 'download' && (
                <Form.Field>
                  <Checkbox style={{ marginTop: '20px' }}
                    radio
                    label={{ children: <CheckboxLabel title={ labels.filesTitle[action] }
                                                      description={ labels.filesDescription[action] } /> }}
                    name='includeData'
                    value='2'
                    checked={includeData === 2}
                    onChange={this.handleChange}
                  />
                </Form.Field>
              )}
            </WithPermission>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={ onClose } negative basic>Cancel</Button>
          <Button type='submit' form={ this.formId } loading={ loading } positive basic>{ labels.action[action] }</Button>
        </Modal.Actions>
      </Modal>
    );
  }

  handleChange = (e, { value }) => this.setState({ includeData: parseInt(value) })

  handleSubmit(evt) {
    evt.preventDefault();

    this.props.onSubmit(this.props.id, this.state.includeData);
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

FormDataModal.defaultProps = {
  opened:       false,
  loading:      false
};

FormDataModal.propTypes = {
  id:           PropTypes.string.isRequired,
  opened:       PropTypes.bool,
  loading:      PropTypes.bool,
  action:       PropTypes.string.isRequired,
  onSubmit:     PropTypes.func.isRequired,
  onClose:      PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default FormDataModal;
