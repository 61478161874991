import React                from 'react';
import { Route, Redirect }  from 'react-router-dom';
import { Grid }             from 'semantic-ui-react';

import useApiResource                         from 'hooks/useApiResource';
import connectResource                        from 'utils/connectResource';
import { getSingleton }                       from 'utils/connectors';
import { organizationAccount, impersonation } from 'resources/organizationResources';
import UserAccountResource                    from 'resources/UserAccountResource';

import RoleRestrictedRouteContainer           from './RoleRestrictedRouteContainer';

import ImpersonationContext from 'contexts/Impersonation';

import HeaderBar      from 'components/HeaderBar';
import SideBar        from 'components/SideBar';
import Main           from 'components/Main';
import Nav            from 'components/organization/Nav';
import BrandBox       from 'components/BrandBox';
import ToolBar        from 'components/ToolBar';
import OrgUserMenu    from './organization/UserMenu';
import AdminUserMenu  from './admin/UserMenu';
import NoAccess       from './organization/NoAccess';
import NoPermission   from './organization/NoPermission';
import WrongDomain    from './organization/WrongDomain';

import ClientsApp               from './organization/clients/App';
import UsersApp                 from './organization/users/App';
import FieldsApp                from './organization/fields/App';
import FormsApp                 from './organization/forms/App';
import SequencesApp             from './organization/sequences/App';
import ReportDefinitionsApp     from './organization/reportDefinitions/App';
import PublicResponsesApp       from './organization/public_responses/App';
import InternalFormResponsesApp from './organization/internalFormResponses/App';
import DashboardApp             from './organization/dashboard/App';
import PermissionsApp           from './organization/permissions/App';
import ImpersonationApp         from './organization/impersonation/App';
import AffiliationsApp          from './organization/affiliations/App';
import DataGroupsApp            from './organization/dataGroups/App';
import CategoriesApp            from './organization/categories/App';
import OrganizationName         from './organization/OrganizationName';
import DownloadExport           from 'components/DownloadExport';

import ReferralListing          from 'caseWorthy/components/ReferralListing';

// -----------------------------------------------------
// Container Definition
// -----------------------------------------------------

const OrganizationContainer = ({ data }) => {
  const userAccount   = useApiResource(UserAccountResource.singletonDetailShape());
  const orgAccount    = data.orgAccount     || {};
  const impersonation = data.impersonation;

  const renderUserMenu = (props) => (
    userAccount.role === 'superuser'
    ? <AdminUserMenu  user={ userAccount } { ...props } />
    : <OrgUserMenu    user={ userAccount } { ...props } />
  );

  return (
    <ImpersonationContext.Provider value={ { impersonation } }>
      <RoleRestrictedRouteContainer userAccount={ userAccount }
                                    permittedRoles={ ['orgadmin','orguser'] }>
                                    
        <HeaderBar className='printable-hidden'>
          <BrandBox width='120px'>
            CoactionNet
          </BrandBox>
          <ToolBar offsetLeft='120px' className='padded-content'>
            <Grid columns={ 2 }>
              <Grid.Column >
                <OrganizationName />
              </Grid.Column>
              <Grid.Column textAlign='right'>
                <Route  path='/'
                        render={ renderUserMenu } />
              </Grid.Column>
            </Grid>
          </ToolBar>
        </HeaderBar>

        <Main offsetTop='50px' className='printable-page'>
          <SideBar className='printable-hidden' width='120px'>
            <Nav />
          </SideBar>
          <Main offsetLeft='120px' className='padded-content stage-content printable-full'>
            <Route  path='/' exact={true}
                    render={ () => <Redirect to='/organization/dashboard' /> } />

            <Route  path='/organization/dashboard'
                    component={ DashboardApp } />

            <Route  path='/organization/users'
                    component={UsersApp} />

            <Route  path='/organization/clients'
                    component={ClientsApp} />

            <Route  path='/organization/fields'
                    component={FieldsApp} />

            <Route  path='/organization/forms'
                    component={FormsApp} />

            <Route  path='/organization/sequences'
                    component={SequencesApp} />

            <Route  path='/organization/reports'
                    component={ReportDefinitionsApp} />

            <Route  path='/organization/public_responses'
                    component={ PublicResponsesApp } />

            <Route  path='/organization/internal_form_responses'
                    component={ InternalFormResponsesApp } />

            <Route  path='/organization/permissions'
                    component={PermissionsApp} />

            <Route  path='/organization/affiliations'
                    component={ AffiliationsApp } />

            <Route  path='/organization/data_groups'
                    component={ DataGroupsApp } />

            <Route  path='/organization/categories'
                    component={ CategoriesApp } />

            <Route  path='/organization/permissiondenied'
                    component={NoPermission} />

            <Route  path='/organization/impersonation'
                    component={ ImpersonationApp } />

            <Route  path='/organzation/caseworthy/referrals'
                    component={ ReferralListing } />

            <Route  path='/organization/noaccess'
                    component={NoAccess} />
        
            <Route path='/download_form_export/:form_id/:id' 
                   component={ DownloadExport } />

            {
              orgAccount.error && userAccount.role !== 'superuser' &&
              <WrongDomain/>
            }
          </Main>
        </Main>
      </RoleRestrictedRouteContainer>
    </ImpersonationContext.Provider>
  );
};

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

export default  connectResource(OrganizationContainer, {
  connectors: {
    impersonation:  getSingleton(impersonation),
    orgAccount:     getSingleton(organizationAccount)
  }
});
