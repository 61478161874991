import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Form }             from 'semantic-ui-react';
import MaskedInput 	        from 'react-maskedinput';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class FreeformShort extends Component {
  constructor(props) {
    super(props);

    this.handleChange  = this.handleChange.bind(this);
  }

  render() {
    const { value, metadata: { mask='', capitalization=false } } = this.props;
    const safeValue = value || '';

    if(mask) {
      return (
        <MaskedInput  mask={ mask }
                      value={ safeValue }
                      onChange={ this.handleChange } />
      );
    } else {
      return (
        <Form.Input
          className={ capitalization ? 'capitalize' : '' }
          value={ safeValue }
        onChange={ this.handleChange } />
      );
    }
  }

  handleChange(evt) {
    const { name, onChange }  = this.props;
    const { value }           = evt.target;
    onChange(evt, { name, value });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

FreeformShort.defaultProps = {
  value:    '',
  metadata: {}
};

FreeformShort.propTypes = {
  value:    PropTypes.string,
  metadata: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default FreeformShort;
