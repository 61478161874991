import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import resourceUrl from 'utils/resourceUrl';
import AuthToken from 'utils/AuthToken';
import useOrganizationResource from 'hooks/useOrganizationResource';
import OrganizationAccountResource from 'resources/organization/OrganizationAccountResource';
import Main from 'components/Main';
import { Segment, SegmentGroup, Header, Message, MessageHeader }  from 'semantic-ui-react';

const DownloadExport = () => {
  const { form_id, id } = useParams();
  const organization = useSelector(state => state.orgdomain);
  const organizationAccount = useOrganizationResource(OrganizationAccountResource.singletonDetailShape());

  const [requestDone, setRequestDone] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { owner } = organizationAccount;

  useEffect(() => {
    const downloadExport = async () => {
      try {
        const url = resourceUrl('/organization/:organization/forms/:form_id/prepare_files/:id', '', { organization, form_id, id });
        const authToken = AuthToken.read();
        const response = await fetch(url, {
          headers: {
            'Authorization': `Bearer ${authToken}`
          }
        });
        setRequestDone(true);
        if (response.ok) {
          setSuccess(true);
          const blob = await response.blob();
          const fileName = getFilenameFromResponse(response);
          const urlBlob = window.URL.createObjectURL(blob);
          const a = document.createElement('a');

          a.href = urlBlob;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          a.remove();

          window.URL.revokeObjectURL(urlBlob);
        } else if (response.status === 404) {
          setSuccess(false);
          setErrorMessage('Export has expired.');
        } else if (response.status === 401) {
          setSuccess(false);
          setErrorMessage('You do not have the necessary permissions to access this feature.');
        }
      } catch (error) {
        alert(error);
        console.error('Error downloading the file:', error);
        setRequestDone(true);
        setSuccess(false);
        setErrorMessage('Error downloading the file.');
      }
    };

    downloadExport();
  }, [form_id, id, owner, organization]);

  return (
    <Main className='detail-pane' overflow="scroll" padded>
      <SegmentGroup horizontal>
        <Segment>
          <Header as='h3'>Form File Export</Header>
          {requestDone ? (
            (owner && success) ? (
              <Message info>
                <MessageHeader>Success</MessageHeader>
                <p>Your file is now being downloaded by the browser.<br/>You can leave this screen.</p>
              </Message>
            ) : (
              <Message warning>
                <MessageHeader>Error</MessageHeader>
                <p>{errorMessage}</p>
              </Message>
            )
          ) : (
            <Message info>
              <MessageHeader>Loading</MessageHeader>
              <p>...</p>
            </Message>
          )}
        </Segment>
      </SegmentGroup>
    </Main>
  );
};

// Helper function to extract filename from response headers
const getFilenameFromResponse = (response) => {
  const contentDisposition = response.headers.get('content-disposition');
  if (!contentDisposition) return 'downloaded_export';
  const matches = /filename="(.*?)"/g.exec(contentDisposition);
  return matches && matches.length > 1 ? matches[1] : 'downloaded_export';
};

export default DownloadExport;
